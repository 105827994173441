input[type=checkbox],
input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border: 2px solid #e4e4e4;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
  overflow: hidden;
  cursor: pointer;
}

input[type=checkbox]::before,
input[type=radio]::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  -webkit-clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  transform: scale(0);
  transform-origin: bottom left;
  overflow: hidden;
}

input[type=checkbox]:checked::before,
input[type=radio]:checked::before {
  transform: scale(1);
}

input[type=checkbox]:checked,
input[type=radio]:checked {
  background-color: #43a5f5;
  border: 2px solid #43a5f5;
}
.wx-btn{padding: 18px;}
.wx-search-container-section {
  width: 100%;
}
.wx-rooms-inputs{
  display: flex;
  align-items: center!important;
}
.wx-search-container-section .wx-search-container .wx-search-row {
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: end;
}
.col-md-1-5 {
  width: 12.5%;
}
.form-control{
  padding: 0px !important;
  outline: none !important;
  height: 25px;
}
.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search {
  height: 50px;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section {

  flex-direction: column !important;
  align-items: center !important;
  
  position: relative;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .wx-modal-input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 3px 0 5px 0 !important;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .wx-modal-input::-moz-placeholder {
  font-size: 15px;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .wx-modal-input::placeholder {
  font-size: 15px;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .bi {
  color: #727272;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section {
  max-height: 350px;
  width: 380px;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  top: 55px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul {
  margin: 0;
  padding: 0;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li {
  border: 1px solid #86acc654;
  padding: 12px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .wx-location-name-box {
  height: 30px;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .wx-location-name-box .wx-location-name {
  font-size: 15px;
  line-height: 2px;
  color: #2f1f19;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .wx-location-name-box .wx-location-title {
  font-size: 13px;
  color: #7b7a7a;
  line-height: 0px;
  letter-spacing: 0.4px;
}

.wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .bi-check {
  color: #43a5f5;
  margin-right: 15px;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search {
  height: 50px;
  border: 1px solid #86acc6;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 30px;
  color: #2f1f19;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  padding: 0 15px;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search input[type=text] {
  border: none;
  outline: none;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list {
  position: absolute;
  top: 55px;
  left: 0px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #dfe3e9;
  border-radius: 8px;
  z-index: 999;
  width: 260px;
}
.wx-min-input, .wx-max-input{
  display: flex;
  height: 35px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wx-room-list li{
  display: block !important;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #86acc654;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search .icon-rotate {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
  color: #43a5f5;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search .icon {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-room-list .wx-checkbox-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-room-list li:first-child {
  border-bottom: 1px solid #eceae8;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field,
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price {
  padding: 0px 10px 10px 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price .wx-price-input,.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field .wx-field-input{
  border:1px solid #86acc654;
  border-radius: 8px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price .wx-price-input input[type=text],.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field .wx-field-input input[type=text] {
  width: 100%;
  padding-left:4px !important;
  border-radius: 8px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price  span, .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field  span{
  font-size: 13px;
  color: #8a8a8a;
  letter-spacing:1px;
}
.wx-field  span{
  font-size: 13px;
  color: #8a8a8a;
  letter-spacing:1px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin: 0;
  height: 48px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons svg {
  color: #43a5f5;
  cursor: pointer;
}

.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons #wx-buton {
  border: none;
  background-color: #fa742d;
  overflow: hidden;
  width: 90px;
  border-radius: 8px;
  margin-right: 7px;
  height: 38px;
  line-height: 35px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons #wx-buton .wx-btn {
  border: none;
  outline: none;
  color: #fff;
  width: 90px;
  font-size:15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search {
  height: auto;
  width: auto;
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #dfe3e9;
  border-radius: 8px;
  padding: 15px 10px 10px 10px;
  z-index: 999;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan {
  display: flex;
  justify-content: space-between;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group {
  display: flex;
  justify-content: space-between;
  color: #8a8a8a;
  margin-right: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group {
  height: 38px;
  display: flex;
  border: 1px solid #86acc654;
  border-radius: 8px;
  overflow: hidden;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .wx-radio-label {
  border-right: 1px solid #86acc654;
  background: #fafaf9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .wx-radio-label input {
  display: none;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .wx-radio-label input:checked~span {
  background-color: #43a5f5;
  color: #fff;
  padding: 0 12px 0 12px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .wx-radio-label input:checked .wx-radio-label {
  background-color: #43a5f5;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .wx-radio-label .wx-radio-btn {
  height: 38px;
  background-color: #fafaf9;
  font-size: 15px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0 12px 0 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .wx-radio-label:last-child {
  border-right: 0px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area {
  display: flex;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-search-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-range-inputs {
  display: flex;
  justify-content: space-between;
  border: 1px solid #86acc654;
  background-color: #fafaf9;
  border-radius: 8px;
  padding: 4px;
  margin-right: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-range-inputs .wx-range-input {
  width: calc(50% - 1px);
  box-sizing: border-box;
  height: 27px;
  background-color: transparent;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-range-inputs .wx-range-input:first-of-type {
  border-right: 1px solid #86acc654;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-elan-number {
  border: 1px solid #86acc6c9;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px;
  margin-left: 13px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-elan-number input {
  padding-left: 5px;
  background-color: #fff;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-elan-number input ::placeholder {
  font-size: 15px;
  color: #727272;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-search-label span {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.6px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-checkbox_label {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #86acc654;
  border-radius: 8px;
  background-color: #fafaf9;
  font-size: 14px;
  line-height: 13px;
  color: #4a4a4a;
  margin-right: 15px;
  cursor: pointer;
}
.adsnumber{
  width: 150px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-checkbox_label input[type=checkbox] {
  height: 15px;
  width: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-checkbox_label .wx-filter-checkbox {
  padding: 9px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor {
  display: flex;
  margin-bottom: 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-floor-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-range-inputs {
  display: flex;
  justify-content: space-between;
  border: 1px solid #86acc654;
  background-color: #fafaf9;
  border-radius: 8px;
  padding: 4px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-range-inputs .wx-range-input {
  width: calc(50% - 1px);
  height: 27px;
  box-sizing: border-box;
  background-color: transparent;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-range-inputs .wx-range-input:first-of-type {
  border-right: 1px solid #86acc654;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor {
  display: flex;
  align-items: flex-end;
  margin-left: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label {
  margin-left: 15px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #86acc654;
  border-radius: 8px;
  line-height: 14px;
  color: #4a4a4a;
  box-sizing: border-box;
  background-color: #fafaf9;
  font-size: 14px;
  cursor: pointer;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox],
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 15px;
  height: 16px;
  border: 2px solid #e4e4e4;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
  overflow: hidden;
}
.wx-search-radio-group .new-checkbox_btn-label input{
  display: none;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label {
  border-right: 1px solid #86acc654;
  background: #fafaf9;
  display: flex;
  align-items: center;
  justify-content: center;
}
 .noright{
  border-right: none !important;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox]::before
{
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  -webkit-clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  transform: scale(0);
  transform-origin: bottom left;
  overflow: hidden;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-wx-checkbox-floor .wx-checkbox_label input[type=checkbox]:checked::before,
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox]:checked::before {
  transform: scale(1);
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox]:checked,
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=radio]:checked {
  background-color: #43a5f5;
  border: 2px solid #43a5f5;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label .wx-filter-checkbox {
  padding: 8px;
  font-size: 15px;
  white-space: nowrap;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-building-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-checkbox-building {
  display: flex;
  align-items: flex-end;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-checkbox-building .wx-checkbox_label {
  margin-right: 8px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #86acc654;
  border-radius: 8px;
  background-color: #fafaf9;
  font-size: 15px;
  line-height: 14px;
  color: #4a4a4a;
  cursor: pointer;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-checkbox-building .wx-checkbox_label .wx-filter-checkbox {
  padding: 8px;
  font-size: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations .wx-search-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations .wx-search-locations-buttons .modal-btn {
  border: 1px solid #86acc654;
  margin-right: 10px;
  color: #4a4a4a;
  background-color: #fafaf9;
  font-size: 15px;
  border-radius: 8px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail {
  width: 100%;
  padding: 10px 0px 10px 0px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label {
  background: #fafaf9;
  border: 1px solid #86acc654;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label input {
  display: none;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label span {
  color: #4a4a4a;
  background-color: #fafaf9;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 3px 10px 3px 10px;
  white-space: nowrap;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label:first-child {
  margin-left: 0;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search input:checked~span {
  background-color: #43a5f5;
  color: #fff;
  white-space: nowrap;
  padding: 3px 10px 3px 10px;
}

.wx-search-container-section .wx-radio-btn{
  white-space: nowrap;
  padding: 4px 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search input:checked .wx-checkbox_btn-label {
  background-color: #43a5f5;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot {
  padding: 8px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #86acc654;
  margin-top: 10px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .hiddenn {
  cursor: pointer;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .hiddenn span,
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .hiddenn .bi {
  color: #43a5f5;
  font-size: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons {
  display: flex;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btnClearAll {
  border: 1px solid #86acc654;
  border-radius: 8px;
  padding: 2px 20px;
  font-size: 15px;
  line-height: 30px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btnClearAll input {
  border: none;
  background-color: transparent;
  color: #4a4a4a;
  font-size: 15px;
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btn-show {
  background-color: #fa742d;
  margin-left: 10px;
  border-radius: 8px;
  
}
.wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btn-show span{
  font-size: 15px;
  color:#fff;
}
.hide {
  display: none;
}
.wx-addAllChecked{
  margin-left: -10px;
}
.wx-addAllChecked ul {
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding-left: 3px;
}
.wx-addAllChecked ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  list-style: none;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  color: #6191b4;
  padding: 5px 12px;
  background: #e1e7ef;
  border-radius: 0.3rem;
  display: inline-flex;
}
.wx-addAllChecked ul li .wx-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  margin-top: 5px;
}
.wx-addAllChecked ul li .wx-box span {
  font-size: 13px;
  list-style: none;
  padding-bottom: 4px;
  line-height: 0px;
}
.wx-addAllChecked ul li .wx-box .wx-title {
  display: inline-block;
  font-size: 11px;
  margin-top: 7px;
  color: #8a8a8a;
}
.wx-addAllChecked ul li:hover {
  background-color: #edebe8;
}
body .wx-search-container:first-child {
  margin-bottom: 15px;
}
.wx-search-row:last-child {
  border-right: none;
}
.active {
  display: block;
}
.deactive {
  display: none;
}
.disabled {
  display: none;
}
.mydisabled {
  background-color: #ececec !important;
}
.mydisabled::placeholder {
  color: #b8b7b7;
}
.fn-24 {
  font-size: 18px;
}
svg {
  cursor: pointer;
}
.wx-search .wx-showplace .inputbox {
  height: 23px;
  z-index: 999;
}
.wx-search .wx-showplace .inputbox span {
  color: grey;
  padding-left: 2px;
}

.wx-rent-input input,.wx-apartment-input input,.wx-rooms-inputs input,.field-inp input, .wx-price-inp input{
  cursor: pointer;
}